.App {
  text-align: center;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: sarabun;
  src: url(../src/assets/fonts/sarabun/Sarabun-Regular.ttf);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #f5f7fb;
}

.form-login {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.form-input {
  /* background-color: #ffffff; */
  width: 24rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  /* padding-left: 10%;
  padding-right: 10%; */
  
}

.video {
  width: 100%;
  height: 500px;
}

.logo-login {
  max-width: 200px;
  margin-bottom: 20px;
}

.sub-menu-nav {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar {
  padding: 0 1rem !important;
}

.navbar-brand {
  padding-top: .7rem !important;
  padding-bottom: .7rem !important;
}

.nav-link {
  padding: 1.1rem 0rem !important;
  color: #335985 !important;
  font-weight: 600;
}

.active-nav {
  border-bottom: 3px solid #206bc4 !important;
  color: #555 !important;
}

.drop-profile {
  /* background: transparent !important; */
  transform: translate3d(-10px, 60px, 0px) !important;
}

.btn {
  text-transform:  none;
}

.btn-warning {
  color: #fff !important;
}

.tag-enable {
  font-size: 12px;
  color: #ffffff;
  background-color: #5eba00;
  padding: 3px 6px;
  border-radius: 5px;
}

.tag-disable {
  font-size: 12px;
  color: #ffffff;
  background-color: red;
  padding: 3px 6px;
  border-radius: 5px;
}

.play-overlay {
  display: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 1s;
}

.play-overlay .icon {
  font-size: 22px;
  color: #ffffff !important;
}

.play-overlay .icon:hover {
  color: #ffffff !important;
}

.poster-preview:hover>.play-overlay {
  display: block !important;
}

/* .rdt_TableRow
.rdt_TableCol
.rdt_TableCol_Sortable
.rdt_TableCell
.rdt_TableHeader
.rdt_TableFooter
.rdt_TableHead
.rdt_TableHeadRow
.rdt_TableBody
.rdt_ExpanderRow */

.rdt_TableHeader {
  display: initial !important;
}

.dataTables_wrapper {
  position: relative;
  /* overflow-x: unset !important; */
  /* overflow-y: unset !important; */
}

.dataTables_wrapper .rdt_TableRow {
  padding-left: 5px;
}

.dataTables_wrapper .rdt_TableCell:nth-child(1) {
  padding-right: 0px !important;
  padding-left: 0px !important;
  display: inline-grid;
  text-align: center;
  max-width: 65px;
  min-width: 65px;
}

.dataTables_wrapper .rdt_TableCell:nth-child(2) {
  padding-left: 0;
}

.dataTables_wrapper .rdt_TableCell:nth-child(4) {
  padding-right: 0px !important;
  padding-left: 0px !important;
  display: inline-grid;
  text-align: center;
  max-width: 100px;
  min-width: 100px;
}

.dataTables_wrapper .rdt_TableCell:nth-child(5) {
  padding-right: 0px !important;
  padding-left: 0px !important;
  display: inline-grid;
  text-align: center;
  max-width: 100px;
  min-width: 100px;
}

.dataTables_wrapper .rdt_TableCell:nth-child(6) {
  padding-right: 0px !important;
  padding-left: 0px !important;
  display: inline-grid;
  text-align: center;
  max-width: 90px;
  min-width: 90px;
}

.dataTables_wrapper .rdt_TableCell:nth-child(7) {
  padding-right: 0px !important;
  padding-left: 0px !important;
  display: inline-grid;
  text-align: center;
  max-width: 100px;
  min-width: 100px;
}

.dataTables_wrapper .rdt_TableCell:nth-child(8) {
  padding-right: 0px !important;
  padding-left: 0px !important;
  display: inline-grid;
  text-align: center;
  max-width: 140px;
  min-width: 140px;
}

.dataTables_wrapper .rdt_TableCell:nth-child(9) {
  max-width: 70px;
  min-width: 50px;
}


/* .dataTables_wrapper .rdt_TableCol {
  display: block !important;
} */

.dataTables_wrapper .rdt_TableCol:nth-child(1) {
  left: 12px;
}

.dataTables_wrapper .rdt_TableCol:nth-child(2) {
  left: 25px;
}

.dataTables_wrapper .rdt_TableCol:nth-child(3) {
  left: 12%;
}

.dataTables_wrapper .rdt_TableCol:nth-child(4) {
  left: 32px;
}

.dataTables_wrapper .rdt_TableCol:nth-child(5) {
  left: 20px;
}

.dataTables_wrapper .rdt_TableCol:nth-child(6) {
  left: 5px;
}

.dataTables_wrapper .rdt_TableCol:nth-child(7) {
  left: -2px;
}

.dataTables_wrapper .rdt_TableCol:nth-child(8) {
  left: 0px;
}



.dataTables_wrapper .rdt_TableRow {
  /* padding-left: 2px; */
  width: 100%;
}

.dataTables_wrapper .bIRQQN {
  max-width: 100% !important;
  display: block !important;
}

.video-js {
  width: 100%;
  height: 100vh;
}

.vjs-menu li.vjs-menu-title {
  display: none;
}

.video-react-progress-control.video-react-control {
  visibility: hidden;
}

.video-react-current-time.video-react-time-control.video-react-control {
  visibility: hidden;
}

.video-react-time-control.video-react-time-divider {
  visibility: hidden;
}

.video-react-duration.video-react-time-control.video-react-control {
  visibility: hidden;
}

.video-area {
  position: relative;
}

.overlay-live {
  position: absolute;
  top: 5%;
  left: 3%;
  z-index: 2147483647;
}

.label-live {
  padding: 3px 4px;
  background-color: red;
  color: #ffffff;
  font-size: 12px;
  border-radius: 3px;
  font-weight: 600;
  font-family: sans-serif;
}

.video-react .video-react-big-play-button {
  top: 40% !important;
  left: 40% !important;
}

@font-face {
  font-family: VideoJS;
  /* src: url("font/VideoJS.eot?#iefix") format("eot"); */
 }

 .vjs-fluid {
  overflow: hidden !important;
}
.vjs-control-bar {
  display: block !important;
}
.vjs-control {
  position: absolute !important;
}
.vjs-progress-control {
  bottom: 28px !important; left: 0 !important;
  height: 10px !important;
  width:  100% !important;
}
/* .vjs-progress-holder  {
  position: absolute !important;
  left: 0 !important; margin: 0 !important;
  height: 4px !important; width:  100% !important;
} */
.vjs-play-progress,
.vjs-load-progress {
  height: 4px !important;
}
.vjs-play-progress:before {
  font-size: 8px !important; top: -2px !important; 
  text-shadow: 0 0 2px black !important 
}

.vjs-progress-control.vjs-control:hover {

}

.vjs-current-time {
  display: block !important;
  left: 140px !important;
}
.vjs-time-divider {
  position: absolute !important;
  display: block !important;
  left: 170px !important;
}
.vjs-remaining-time {
  display: none !important;   
}
.vjs-duration {
  display: block !important;
  left: 185px !important;
}
/* .vjs-volume-menu-button {
  position: absolute !important;
  bottom: 0 !important; right: 55px !important;
} */
.vjs-playback-rate {
  position: absolute !important;
  bottom: 0 !important; right: 28px !important;
}
.vjs-fullscreen-control {
  position: absolute !important;
  bottom: 0 !important; right: 0 !important;
}

.vjs-subs-caps-button.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button {
  position: absolute !important;
  right: 75px !important;
}


.vjs-picture-in-picture-control {
  display: none !important;
  /* position: absolute !important;
  bottom: 0 !important; right: 32px !important; */
}

.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button.vjs-quality-selector {
  position: absolute !important;
  bottom: 0 !important; right: 35px !important;
}

/* .vjs-volume-panel {
  position: absolute !important;
  bottom: 0 !important; left: 35px !important;
} */

/* .vjs-volume-control.vjs-control.vjs-volume-vertical {
  bottom: 35px !important;
  left: 5px !important;
} */

.video-js .vjs-control-bar {
  background-color: transparent !important;
}

.vjs-live-control {
  display: none !important;
}

.video-js .vjs-volume-panel .vjs-volume-control {
  opacity: 1 !important;
}

.video-js .vjs-volume-control.vjs-volume-horizontal {
  width: 5em !important;
  left: 30px;
}

.vjs-volume-panel {
  position: absolute !important;
  bottom: 0 !important;
  left: 40px !important;
}

.vjs-seek-button.skip-back {
  position: absolute !important;
  bottom: 0 !important;
  left: 33px !important;
}

.vjs-seek-button.skip-forward {
  position: absolute !important;
  bottom: 0 !important;
  left: 65px !important;
}

.popover-copy {
  background-color: #777777;
  color: #ffffff;
  position: absolute;
  top: -45px;
  left: -3px;
  border-radius: 5px;
  padding: 3px 6px 6px 6px;
}

.arrow {
  border: solid #777777;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  background-color: #777777;
  position: absolute;
  bottom: -5px;
  left: 43%;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.text-copy {
  font-size: 12px;
  font-weight: 500;
}

.vjs-seek-to-live-control ,.vjs-volume-tooltip { display: none !important; }

.video-live .vjs-play-control {
  left: 0;
}

.video-live .vjs-current-time,
.video-live .vjs-time-divider,
.video-live .vjs-duration,
.video-live .vjs-picture-in-picture-control {
  display: none !important;
}

.video-live .vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button.vjs-quality-selector {
  /* position: absolute !important;
  bottom: 0 !important; */
  right: 35px !important;
}

.vjs-max-quality-selector-button {
  right: 70px;
}

.vjs-text-track-display div {
  /* font-size: 1.2em; */
  font-family: sarabun !important;
  background-color: transparent !important;
  text-shadow: -2px 1px 0 #000, 2px 1px 0 #000, 2px -1px 0 #000, -2px -1px 0 #000;
  font: 40.38px sans-serif;
}

.vjs-texttrack-settings {
  display: none;
}

.Toastify__toast--success {
  /* background-color: #61dafb !important; */
  color: #468847 !important;
  background-color: #DFF0D8 !important;
  border-color: #D6E9C6 !important;
}

.Toastify__toast--error {
  /* background-color: #61dafb !important; */
  color: #B94A48 !important;
  background-color: #F2DEDE !important;
  border-color: #EED3D7 !important;
  background-image: url('./images/error.png');
}

.Toastify__close-button.Toastify__close-button--success {
  color: #468847 !important;
}

.Toastify__toast-body {
  flex: 0 0 auto !important;
  padding: 0 !important;
}

.Toastify__toast {
  min-height: 15px !important;
  font-size: 15px !important;
}

.hd-tag {
  position: absolute;
  right: 12px;
  /* top: 3px; */
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
}

.btn-thumbnail {
  /* cursor: pointer; */
}

.btn-thumbnail:hover {
  cursor: pointer !important;
  transition: all 0.3s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.40);
}

.active-thumbnail {
  /* cursor: pointer; */
  transition: all 0.3s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.40);
}

.vjs-icon-cog:after {
  /* content: 'HD';
  position: absolute;
  font-size: 9px;
  font-weight: 600;
  font-family: var(--bs-font-sans-serif);
  top: 0;
  right: 0; */
}

.tag-hd {
  position: absolute;
  font-size: 8px;
  font-weight: 900;
  color: #ffffff;
  font-family: var(--bs-font-sans-serif);
  top: 3px;
  right: 0;
}

.tag-auto {
  position: absolute;
  font-size: 8px;
  font-weight: 900;
  color: red;
  font-family: var(--bs-font-sans-serif);
  top: 3px;
  right: -6px;
}

@font-face {
  font-family: VideoJS;
  /* src: url("font/VideoJS.eot?#iefix") format("eot"); */
 }

 /* .video-js .vjs-seek-button.skip-back.skip-10::before {
  content: '\f048'
}
.video-js.vjs-v6 .vjs-seek-button.skip-back.skip-10 .vjs-icon-placeholder::before,
.video-js.vjs-v7 .vjs-seek-button.skip-back.skip-10 .vjs-icon-placeholder::before {
  content: '\f048'
} */

.video-js .vjs-seek-button.skip-back {
  
}

/* .vjs-menu-button.vjs-menu-button-popup.vjs-button .vjs-icon-placeholder:after {
  content: 'HD';
  position: absolute;
  font-size: 9px;
  font-weight: 600;
  font-family: var(--bs-font-sans-serif);
  top: 0;
  right: 0;
} */


/* .vjs-menu-button.vjs-menu-button-popup.vjs-button > .vjs-icon-placeholder:before {
  font-family: VideoJS;
  content: "\f110"; 
} */

/* .no-live {
  position: absolute;
  color: #ffffff;
  font-size: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
} */

.vjs-menu-item-text::before {
  text-transform: uppercase;
}
